<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>运营与营销</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/aimActivity' }">限时活动</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info margin-l-sm">
            <el-form ref="form" :model="form" label-width="120px" v-loading="formLoading">
                <el-form-item label="活动名称" prop="name" class="form_input" :rules="[
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                ]">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="封面" prop="cover_img" :rules="[
                    { required: true, message: '请输入活动封面', trigger: 'change' },
                ]">
                    <edit-cover @success="uploadSuccess" :pic="path + form.cover_img"></edit-cover>
                </el-form-item>

                <el-form-item label="活动介绍" prop="introduce" :rules="[
                    { required: true, message: '请输入活动介绍', trigger: 'blur' },
                ]">
                    <editor @onChange="onChange" :content="form.introduce"></editor>
                </el-form-item>
                <el-form-item label="开始时间" prop="start_at" :rules="[
                    { required: true, message: '请选择开始时间', trigger: 'change' },
                ]">
                    <el-date-picker v-model="form.start_at" type="datetime" placeholder="选择开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="end_at" :rules="[
                    { required: true, message: '请选择结束时间', trigger: 'change' },
                ]">
                    <el-date-picker v-model="form.end_at" type="datetime" placeholder="选择结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="应签到天数" prop="sign_days" :rules="[
                    { required: true, message: '应签到天数', trigger: 'change' },
                ]">
                    <el-input-number v-model="form.sign_days" :min="1" size="small">
                    </el-input-number>
                </el-form-item>

                <el-form-item label="奖励积分" prop="reward_integral" :rules="[
                    { required: true, message: '奖励积分', trigger: 'change' },
                ]">
                    <el-input-number v-model="form.reward_integral" :min="0" size="small">
                    </el-input-number>
                </el-form-item>
                <el-form-item label="状态 " prop="reward_integral" :rules="[
                    { required: true, message: '状态 ', trigger: 'change' },
                ]">
                    <el-switch v-model="form.status" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>

                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
import editCover from "../../../components/editCover";
import Editor from "../../../components/editor";
import { mapActions } from "vuex";
export default {
    name: "detail",
    components: {
        editCover,
        Editor
    },
    data() {
        return {
            //图片前缀
            path: config.imageUrl,
            formLoading: false,
            //表单数据
            form: {
                sign_days: 1,
                reward_integral: 0,
                status: true
            },
            //提交的加载动画
            loading: false,
            uploadPath: 'store',
        }
    },
    methods: {
        ...mapActions('aimActivity', ['addAimActivity', 'getAimActivityDetail']),
        //图片
        uploadSuccess(path) {
            this.form.cover_img = path
        },
        //富文本
        onChange(content) {
            this.form.introduce = content
        },
        async add(form) {
            const { res_info } = await this.addAimActivity(form)
            if (res_info != 'ok') return
            this.loading = false
            this.$router.go(-1);
            this.$message.success('编辑成功');
        },
        //提交表单
        onSubmit() {
            let form = JSON.parse(JSON.stringify(this.form))
            let _this = this
            this.$refs.form.validate((valid) => {
                if (valid) {
                    _this.loading = true
                    form.status = form.status ? 1 : 0
                    _this.add(form)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //初始化数据
        async initData() {
            this.formLoading = true
            const { res_info, data } = await this.getAimActivityDetail({ id: parseFloat(this.$route.params.id) })
            console.log(res_info);
            if ('ok' != res_info) return
            this.form = data
            this.form.status = data.status == 1 ? true : false
            this.form.id = parseFloat(this.$route.params.id)
            this.formLoading = false
        },
    },
    created() {
        this.initData();
    },
}
</script>

<style scoped>
.margin-l-sm {
    margin-left: 1.25rem;
}

.form_input {
    width: 20%;
}
</style>
